import {
  GQLPhysicalAddress,
  GQLProgram,
  GQLSubscriptionStatus,
  GQLUserSubscription,
  Maybe,
} from '../gql.generated';
import { notEmpty } from './predicates/not-empty';

const INVALID_PROGRAMS = [GQLProgram.CREATOR_SEED];

export const filterOutInvalidPrograms = (
  subscriptions: Maybe<GQLUserSubscription>[]
): GQLUserSubscription[] => {
  const presentSubscriptions = subscriptions
    .filter((it) => it !== null)
    .map((it) => it as GQLUserSubscription);

  return presentSubscriptions.filter(
    (it) => !it.program || !INVALID_PROGRAMS.includes(it.program)
  );
};

/**
 * Check if a given subscription is active or not.
 *
 * @param subscription GQLUserSubscription object that represent the User Subscription
 *
 * @returns Boolean true if it is an active sub. Otherwise it returns false.
 */
export const isActiveSubscription = (
  subscription?: GQLUserSubscription
): boolean =>
  !!subscription &&
  (subscription.status == GQLSubscriptionStatus.ACTIVE ||
    subscription.status == GQLSubscriptionStatus.DO_NOT_RENEW);

/**
 *
 * @param subscriptions Array of GQLUserSubscription objects with the User Subscriptions
 * @param program GQLProgram object with a valid Subscription Program
 *
 * @returns Boolean true if the subscription that matches with the given program is active. Otherwise it returns false.
 */
export const hasActiveSubscriptionByProgram = (
  subscriptions?: Array<GQLUserSubscription>,
  program?: Maybe<GQLProgram>
): boolean =>
  !!subscriptions?.length &&
  !!subscriptions.find(
    (subscription: GQLUserSubscription) =>
      subscription.program === program && isActiveSubscription(subscription)
  );

/**
 * Check if there is any active non-R! Subscription into the given list of User Subscriptions.
 *
 * @param subscriptions Array with all the subscriptions of the User
 *
 * @returns Boolean indicating whether the user has or doesn't have any active non-R! subscription
 */
export const hasActiveSubscription = (
  subscriptions?: Array<GQLUserSubscription>
): boolean =>
  !!subscriptions?.length &&
  subscriptions.some(
    (subscription: GQLUserSubscription) =>
      subscription.program !== GQLProgram.REFRESHMENTS &&
      isActiveSubscription(subscription)
  );

/**
 * Checks if there is any Active or DNR non-R! Subscription into the given collection of Maybe User Subscriptions.
 *
 * @param maybeSubscriptions Maybe an Array with Maybe all the user subscriptions
 *
 * @returns Boolean indicating whether the user has any Active or DNR non-R! subscription
 */
export const hasActiveSubscriptionMaybe = (
  maybeSubscriptions?: Maybe<Array<Maybe<GQLUserSubscription>>>
): boolean => {
  const subscriptions = maybeSubscriptions?.filter(notEmpty) ?? [];
  return hasActiveSubscription(subscriptions);
};

export const getSubscriptionShippingAddressByProgram = (
  subscriptions?: Array<GQLUserSubscription>,
  program?: Maybe<GQLProgram>
): Maybe<GQLPhysicalAddress> =>
  (!!subscriptions?.length &&
    subscriptions.find(
      (subscription: GQLUserSubscription) => subscription.program === program
    )?.shippingAddress) ||
  null;

/**
 * Check if there is a canceled Subscription that matches with the given program Subscription into the given list of User Subscriptions.
 *
 * @param subscriptions Array with all the subscriptions of the User
 * @param program GQLProgram object with a valid Subscription Program
 *
 * @returns Boolean true if the subscription that matches with the given program is canceled. Otherwise it returns false.
 */
export const hasCanceledSubscriptionByProgram = (
  subscriptions?: Array<GQLUserSubscription>,
  program?: Maybe<GQLProgram>
): boolean =>
  !!subscriptions?.length &&
  subscriptions.some(
    (subscription: GQLUserSubscription) =>
      subscription.program === program &&
      subscription.status === GQLSubscriptionStatus.CANCELED
  );

/**
 * Check if there is any active non-R! Subscription, and also different than the given program, into the given list of User Subscriptions.
 *
 * @param subscriptions Array with all the subscriptions of the User
 * @param program GQLProgram object with a valid Subscription Program
 *
 * @returns Boolean indicating whether the user has or doesn't have any active non-R! subscription
 */
export const hasAnyOtherActiveSubscription = (
  subscriptions?: Array<GQLUserSubscription>,
  program?: Maybe<GQLProgram>
): boolean =>
  !!subscriptions?.length &&
  subscriptions.some(
    (subscription: GQLUserSubscription) =>
      subscription.program !== GQLProgram.REFRESHMENTS &&
      subscription.program !== program &&
      isActiveSubscription(subscription)
  );
